import React, { useState } from 'react';

import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import Modal from '../Layout/Modal/Modal';
import Api from '../../services/api';
import classes from './AddHouse.module.css';

const AddHouse = props => {
  const [address, setAddress] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const { conId } = props.match.params;
  const { history } = props;
  const onSubmitHandler = e => {
    e.preventDefault();
    setShowModal(true);
    Api.post(
      `/con/${conId}/add`,
      {
        address,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    )
      .then(() => {
        history.push(`/dashboard/con/${conId}`);
      })
      .catch(err => {
        if (err.response.status === 403) return history.push('/logout');
        setError({
          status: true,
          message: 'Casa com esse endereço já cadastrada!',
        });
        setShowModal(false);
      });
  };

  return (
    <div className={classes.Content}>
      <Modal show={showModal} Loading />
      <h1>Adicionar Casa</h1>
      <form onSubmit={onSubmitHandler}>
        <ErrorMsg errorInfo={error} setErrorInfo={setError} />
        <label htmlFor="address">Endereço</label>
        <input
          type="text"
          id="address"
          value={address}
          onChange={e => setAddress(e.target.value)}
          required
        />
        <button type="submit">Cadastrar</button>
      </form>
    </div>
  );
};

export default AddHouse;
