import React, { useEffect, useContext } from 'react';

import Modal from '../../components/Layout/Modal/Modal';
import Api from '../../services/api';
import { AuthContext } from '../../context/auth-context';

export default function Logout({ history }) {
  const authContext = useContext(AuthContext);
  useEffect(() => {
    Api.get('/logout', {
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    authContext.logout();
    return history.replace('/');
  }, [history, authContext]);

  return <Modal show Loading closeModal={() => {}} />;
}
