import React, { useState } from 'react';

import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import classes from './AddPhone.module.css';
import Modal from '../Layout/Modal/Modal';
import Api from '../../services/api';

const AddPhone = props => {
  const { conId, houseId } = props.match.params;
  const { history } = props;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [priority, setPriority] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  const telefoneMask = e => {
    let { value } = e.target;
    // Remove tudo o que não é dígito
    value = value.replace(/\D/g, '');
    // Coloca um hífen entre o segundo e o terceiro dígitos
    value = value.replace(/(\d{2})(\d)/, '$1-$2');

    if (value.length <= 11) {
      // Fixo
      // Coloca um hífen entre o quinto e o sexto dígitos
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      // Celular
      // Coloca um hífen entre o sexto e o setimo dígitos
      value = value.replace(/(\d{5})(\d)/, '$1-$2');
    }
    return setPhone(value);
  };

  const onSubmitHandler = e => {
    e.preventDefault();
    if (phone.length < 12)
      return setError({ status: true, message: 'Telefone inválido!' });
    setShowModal(true);
    Api.post(
      `/con/${conId}/house/${houseId}/add`,
      {
        name,
        phone,
        priority,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    )
      .then(() => {
        history.push(`/dashboard/con/${conId}/house/${houseId}`);
      })
      .catch(err => {
        if (err.response.status === 403) return history.push('/logout');
        setError({
          status: true,
          message: 'Contato com esse número já cadastrado!',
        });
        setShowModal(false);
      });
  };

  return (
    <div className={classes.Content}>
      <Modal show={showModal} Loading />
      <h1>Adicionar Telefone</h1>
      <form onSubmit={onSubmitHandler}>
        <ErrorMsg errorInfo={error} setErrorInfo={setError} />
        <label htmlFor="name">Nome</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={e => setName(e.target.value.toLocaleUpperCase())}
          placeholder="Nome do Morador"
          required
        />
        <label htmlFor="phone">Telefone</label>
        <input
          type="text"
          id="phone"
          value={phone}
          onChange={telefoneMask}
          maxLength="13"
          placeholder="Telefone do Morador"
          required
        />
        <label htmlFor="priority">Prioridade</label>
        <input
          type="text"
          id="priority"
          value={priority}
          onChange={e => setPriority(e.target.value.replace(/\D/g, ''))}
          placeholder="Prioridade do Número"
          required
        />
        <button type="submit">Adicionar</button>
      </form>
    </div>
  );
};

export default AddPhone;
