import React from 'react';

import Routes from './routes';
import AuthContextProvider from './context/auth-context';
import { setCssVars } from './functions/functions';

function App() {
  setCssVars();
  return (
    <AuthContextProvider>
      <Routes />
    </AuthContextProvider>
  );
}

export default App;
