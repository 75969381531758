import React, { useContext } from 'react';

import NavbarItem from './NavbarItem/NavbarItem';
import { AuthContext } from '../../../context/auth-context';
import classes from './Navbar.module.css';

const Navbar = () => {
  const authContext = useContext(AuthContext);

  return (
    <ul className={classes.NavbarItems}>
      <NavbarItem link="/" exact>
        Neighbourhud
      </NavbarItem>
      {!authContext.isAuth ? (
        <>
          <NavbarItem link="/login">Login</NavbarItem>
          <NavbarItem link="/config">Configurações</NavbarItem>
        </>
      ) : (
        <>
          <NavbarItem link="/dashboard">Painel</NavbarItem>
          <NavbarItem link="/config">Configurações</NavbarItem>
          <NavbarItem link="/logout">Logout</NavbarItem>
        </>
      )}
    </ul>
  );
};

export default Navbar;
