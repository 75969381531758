import React from 'react';
import { withStyles } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';

import classes from './ToggleSwitch.module.css';

const ToggleSwitch = props => {
  const Toggle = withStyles(theme => ({
    root: {
      width: 100,
      height: 59,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(41px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: props.color,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 55,
      height: 55,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 64 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  return (
    <div className={classes.ToggleSwitch}>
      <label>{props.label}</label>
      <Toggle
        checked={props.checked}
        onChange={e => props.setChecked(e.target.checked)}
        value={props.checked}
      />
    </div>
  );
};

export default ToggleSwitch;
