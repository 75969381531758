import React, { useEffect, useContext, useState } from 'react';
import { Route } from 'react-router-dom';

import Modal from '../../components/Layout/Modal/Modal';
import ErrorMsg from '../../components/Layout/ErrorMsg/ErrorMsg';
import Api from '../../services/api';
import NavbarItem from '../../components/Layout/Navbar/NavbarItem/NavbarItem';
import { AuthContext } from '../../context/auth-context';
import Condo from '../../components/Condo/Condo';
import AddCondo from '../../components/AddCondo/AddCondo';
import RemCondo from '../../components/RemCondo/RemCondo';
import classes from './Dashboard.module.css';

export default function Dashboard(props) {
  const authContext = useContext(AuthContext);
  const [condos, setCondos] = useState([]);
  const [visibleCondos, setVisibleCondos] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });

  const { history, match, location } = props;
  useEffect(() => {
    if (!authContext.isAuth) {
      history.replace('/');
    }
  }, [history, authContext.isAuth]);

  useEffect(() => {
    if (history.location.pathname !== '/dashboard') return;
    setShowModal(true);
    Api.get('/con', {
      headers: {
        token: localStorage.getItem('token'),
      },
    })
      .then(res => {
        setShowModal(false);
        setCondos(res.data);
        setVisibleCondos(res.data);
        setError({
          status: res.data.length === 0,
          message: 'Nenhum condomínio cadastrado!',
        });
      })
      .catch(() => {
        setShowModal(false);
        history.push('/logout');
      });
  }, [history, history.location]);

  const doSearchHandler = e => {
    setSearchInput(e.target.value);
    setVisibleCondos(
      e.target.value !== ''
        ? condos.filter(con => con.name.startsWith(e.target.value))
        : [...condos]
    );
  };

  return (
    <div className={classes.MainDiv}>
      <Modal show={showModal} Loading />
      <Route path={`${match.url}/`} exact>
        <div className={classes.Content}>
          <h1>Meus Condomínios</h1>
          <ErrorMsg errorInfo={error} setErrorInfo={setError} />
          <form>
            <input
              type="text"
              placeholder="Pesquisar condomínio"
              value={searchInput}
              onChange={doSearchHandler}
            />
          </form>
          <ul className={classes.List}>
            {visibleCondos.map(con => (
              <li key={con.condoId}>
                <button
                  type="button"
                  onClick={() =>
                    history.push(`${match.url}/con/${con.condoId}`)
                  }
                >
                  {con.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Route>
      <Route path={`${match.url}/add`} component={AddCondo} />
      <Route
        path={`${match.url}/rem`}
        render={() => <RemCondo condos={condos} />}
      />
      <Route path={`${match.url}/con/:conId`} component={Condo} />
      {!location.pathname.startsWith('/dashboard/con') && (
        <div className={classes.SideNavbar}>
          <ul>
            <NavbarItem link={match.url} exact>
              Ver Condomínios
            </NavbarItem>
            <NavbarItem link={`${match.url}/add`}>
              Adicionar Condomínio
            </NavbarItem>
            <NavbarItem link={`${match.url}/rem`}>
              Remover Condomínio
            </NavbarItem>
          </ul>
        </div>
      )}
    </div>
  );
}
