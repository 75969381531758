import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../../context/auth-context';
import logo from '../../assets/logo.png';

export default function Home() {
  const authContext = useContext(AuthContext);
  return (
    <div>
      {authContext.isAuth ? (
        <Redirect to="/dashboard" />
      ) : (
        <Redirect to="/login" />
      )}
      <img src={logo} alt="logo" />
    </div>
  );
}
