import React, { useState, useEffect } from 'react';

import Api from '../../services/api';
import ErroMsg from '../Layout/ErrorMsg/ErrorMsg';
import Modal from '../Layout/Modal/Modal';
import classes from './Package.module.css';

const Package = props => {
  const [textMessage, setTextMessage] = useState(
    'Há uma nova encomenda para seu endereço na portaria!'
  );
  const [contactId, setContactId] = useState('');
  const [error, setError] = useState({ status: false, message: '' });
  const [modal, setModal] = useState(false);
  const { houseInfo, history, url, houseId, conId } = props;

  useEffect(() => {
    if (houseInfo.length === 0)
      return history.push(`${url.replace('/package', '')}`);
  }, [houseInfo, history, url]);

  const handleSubmit = e => {
    e.preventDefault();
    setModal(true);
    Api.post(
      `/con/${conId}/house/${houseId}/ring/${contactId}`,
      {
        type: 1,
        data: textMessage,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    )
      .then(() => {
        setModal(false);
        setContactId('');
        setError({
          status: true,
          message: 'Mensagem enviada com sucesso!',
        });
      })
      .catch(err => {
        setModal(false);
        if (err.response.status === 403) return history.push('/logout');
        setError({
          status: true,
          message: 'Erro ao enviar mensagem!',
        });
      });
  };

  return (
    <div className={classes.Content}>
      <Modal show={modal} Loading />
      <h1>Aviso de Encomenda</h1>
      <form onSubmit={handleSubmit}>
        <ErroMsg errorInfo={error} setErrorInfo={setError} />
        <label>Mensagem SMS</label>
        <textarea
          maxLength="160"
          onChange={e => setTextMessage(e.target.value)}
          value={textMessage}
          required
        />
        <label>Telefone</label>
        <select
          value={contactId}
          onChange={e => setContactId(e.target.value)}
          required
        >
          <option value="" disabled hidden>
            Selecione
          </option>
          {houseInfo.map((h, i) => (
            <option key={i} value={i}>
              {`${h.name} - ${h.phone}`}
            </option>
          ))}
        </select>
        <button type="submit">Enviar Aviso</button>
      </form>
    </div>
  );
};

export default Package;
