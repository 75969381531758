import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import Modal from '../Layout/Modal/Modal';
import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import NavbarItem from '../Layout/Navbar/NavbarItem/NavbarItem';
import AddHouse from '../AddHouse/AddHouse';
import RemHouse from '../RemHouse/RemHouse';
import House from '../House/House';
import Api from '../../services/api';
import classes from './Condo.module.css';

const Houses = props => {
  const { match, history, location } = props;
  const [houses, setHouses] = useState([]);
  const [visibleHouses, setVisibleHouses] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });
  const { conId } = match.params;

  useEffect(() => {
    if (!match.isExact) return;
    setShowModal(true);
    Api.get(`/con/${conId}`, {
      headers: {
        token: localStorage.getItem('token'),
      },
    })
      .then(res => {
        setShowModal(false);
        setHouses(res.data);
        setVisibleHouses(res.data);
        setError({
          status: res.data.length === 0,
          message: 'Nenhuma casa cadastrada!',
        });
      })
      .catch(err => {
        setShowModal(false);
        if (err.response.status === 403) return history.push('/logout');
      });
  }, [match, history, conId]);

  const doSearchHandler = e => {
    setSearchInput(e.target.value);
    setVisibleHouses(
      e.target.value !== ''
        ? houses.filter(house => house.address.startsWith(e.target.value))
        : [...houses]
    );
  };

  return (
    <>
      <Modal show={showModal} Loading />
      <Route path={`${match.path}`} exact>
        <div className={classes.Content}>
          <button type="button" onClick={() => history.push('/dashboard')}>
            Voltar
          </button>
          <h1>Casas</h1>
          <ErrorMsg errorInfo={error} setErrorInfo={setError} />
          <div className={classes.Input}>
            <input
              type="text"
              placeholder="Pesquisar casa"
              value={searchInput}
              onChange={doSearchHandler}
            />
          </div>
          <ul>
            {visibleHouses.map(house => (
              <li key={house.houseId}>
                <button
                  type="button"
                  onClick={() =>
                    history.push(`${match.url}/house/${house.houseId}`)
                  }
                >
                  {house.address}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Route>
      <Route path={`${match.path}/add`} component={AddHouse} />
      <Route path={`${match.path}/house/:houseId`} component={House} />
      <Route
        path={`${match.path}/rem`}
        render={() => <RemHouse conId={conId} houses={houses} />}
      />

      {!location.pathname.startsWith(`/dashboard/con/${conId}/house`) && (
        <div className={classes.SideNavbar}>
          <ul>
            <NavbarItem link={match.url} exact>
              Ver casas
            </NavbarItem>
            <NavbarItem link={`${match.url}/add`}>Adicionar casa</NavbarItem>
            <NavbarItem link={`${match.url}/rem`}>Remover casa</NavbarItem>
          </ul>
        </div>
      )}
    </>
  );
};

export default Houses;
