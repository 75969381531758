import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Route } from 'react-router-dom';
import socketio from 'socket.io-client';

import Api, { baseURL } from '../../services/api';
import Modal from '../Layout/Modal/Modal';
import AddPhone from '../AddPhone/AddPhone';
import RemPhone from '../RemPhone/RemPhone';
import Package from '../Package/Package';
import NavbarItem from '../Layout/Navbar/NavbarItem/NavbarItem';
import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import classes from './House.module.css';

const House = props => {
  const [houseInfo, setHouseInfo] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [visitorName, setVisitorName] = useState('');
  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const { history, match } = props;
  const { conId, houseId } = props.match.params;
  const { url, path } = props.match;
  const sortNumbers = useCallback(d => {
    const info = [...d];
    for (let i = 1; i < info.length; i += 1) {
      let j = i - 1;
      const aux = info[i];
      while (j >= 0 && +info[j].priority < +aux.priority) {
        info[j + 1] = info[j];
        j -= 1;
      }
      info[j + 1] = aux;
    }
    return info;
  }, []);

  const socket = useMemo(() => {
    return socketio(baseURL, {
      query: { token: localStorage.getItem('token') },
    });
  }, []);

  useEffect(() => {
    socket.on('visitResponse', data => {
      let message = '';
      switch (data) {
        case 'accepted':
          message = 'Visita autorizada!';
          break;
        case 'rejected':
          message = 'Visita não autorizada!';
          break;
        default:
          message = 'Ligação não atendida!';
          break;
      }
      setError({
        status: true,
        message,
      });
    });
  }, [socket]);

  useEffect(() => {
    if (!match.isExact) return;
    setShowModal(true);
    Api.get(`/con/${conId}/house/${houseId}`, {
      headers: {
        token: localStorage.getItem('token'),
      },
    })
      .then(res => {
        setShowModal(false);
        setContacts(res.data);
        setHouseInfo(sortNumbers(res.data));
        setError({
          status: res.data.length === 0,
          message: 'Nenhum contato cadastrado!',
        });
      })
      .catch(err => {
        setShowModal(false);
        if (err.response && err.response.status === 403)
          return history.push('/logout');
      });
  }, [conId, houseId, sortNumbers, history, match.isExact]);

  const callContact = contactId => {
    if (visitorName === '') return;
    setShowModal(true);
    Api.post(
      `/con/${conId}/house/${houseId}/ring/${contactId}`,
      {
        type: 0,
        data: visitorName,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    )
      .then(() => {
        setShowModal(false);
        setError({
          status: true,
          message: 'Aguardando resposta da ligação...',
        });
      })
      .catch(err => {
        setShowModal(false);
        if (err.response.status === 403) return history.push('/logout');
        setError({
          status: true,
          message: 'Erro ao enviar aviso de visita!',
        });
      });
  };

  return (
    <>
      <Modal show={showModal} Loading />
      <Route path={path} exact>
        <div className={classes.Content}>
          <button
            type="button"
            onClick={() => history.push(`/dashboard/con/${conId}`)}
          >
            Voltar
          </button>
          <h1>Telefones</h1>
          <ErrorMsg errorInfo={error} setErrorInfo={setError} />
          <form>
            <input
              type="text"
              placeholder="Nome do Visitante"
              value={visitorName}
              onChange={e => setVisitorName(e.target.value)}
            />
          </form>
          <ul>
            {houseInfo.map(hs => (
              <li key={hs.phone}>
                <div>
                  <p>{hs.name}</p>
                  <p>{hs.phone}</p>
                </div>
                <button
                  type="button"
                  onClick={() => callContact(contacts.indexOf(hs))}
                >
                  Aviso de Visita
                </button>{' '}
              </li>
            ))}
          </ul>
        </div>
      </Route>
      <Route path={`${path}/add`} component={AddPhone} />
      <Route
        path={`${path}/package`}
        render={() => (
          <Package
            houseInfo={houseInfo}
            history={history}
            url={url}
            conId={conId}
            houseId={houseId}
          />
        )}
      />
      <Route
        path={`${path}/rem`}
        render={() => (
          <RemPhone phones={contacts} conId={conId} houseId={houseId} />
        )}
      />
      <div className={classes.SideNavbar}>
        <ul>
          <NavbarItem link={url} exact>
            Ver contatos
          </NavbarItem>
          <NavbarItem link={`${url}/add`}>Adicionar contato</NavbarItem>
          <NavbarItem link={`${url}/rem`}>Remover contato</NavbarItem>
          <NavbarItem link={`${url}/package`}>Aviso de encomenda</NavbarItem>
        </ul>
      </div>
    </>
  );
};

export default House;
