import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import Modal from '../Layout/Modal/Modal';
import Api from '../../services/api';
import classes from './RemPhone.module.css';

const RemPhone = props => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });
  const [selectedPhone, setSelectedPhone] = useState('');
  const history = useHistory();
  const location = useLocation();
  const { phones, conId, houseId } = props;

  useEffect(() => {
    if (phones.length === 0)
      return history.push(location.pathname.replace('/rem', ''));
  }, [phones, history, location]);

  const handleSubmit = e => {
    e.preventDefault();
    setModal(true);
    Api.delete(`/con/${conId}/house/${houseId}/rem/${selectedPhone}`, {
      headers: {
        token: localStorage.getItem('token'),
      },
    })
      .then(() => {
        setModal(false);
        history.push(location.pathname.replace('/rem', ''));
      })
      .catch(() => {
        setModal(false);
        setError({ status: true, message: 'Erro ao remover contato!' });
      });
  };

  return (
    <>
      <Modal show={modal} Loading />
      <div className={classes.Content}>
        <h1>Remover Contato</h1>
        <form onSubmit={handleSubmit}>
          <ErrorMsg errorInfo={error} setErrorInfo={setError} />
          <label>Contato</label>
          <select
            value={selectedPhone}
            onChange={e => setSelectedPhone(e.target.value)}
            required
          >
            <option value="" disabled hidden>
              Selecione
            </option>
            {phones.map((phon, i) => (
              <option key={i} value={i}>
                {`${phon.name} - ${phon.phone}`}
              </option>
            ))}
          </select>
          <button type="submit">Remover</button>
        </form>
      </div>
    </>
  );
};

export default RemPhone;
