import React from 'react';

import classes from './ErrorMsg.module.css';

const ErrorMsg = props => {
  const styles = [classes.ErrorMsg];
  if (!props.errorInfo.status) styles.push(classes.Invisible);
  return (
    <div className={styles.join(' ')}>
      <button
        type="button"
        onClick={() =>
          props.setErrorInfo({
            status: false,
            message: props.errorInfo.message,
          })
        }
      >
        &times;
      </button>
      <span>{props.errorInfo.message}</span>
    </div>
  );
};

export default ErrorMsg;
