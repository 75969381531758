import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import Modal from '../Layout/Modal/Modal';
import Api from '../../services/api';
import classes from './AddCondo.module.css';

const AddCondo = props => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });

  const onSubmitHandler = e => {
    e.preventDefault();
    if (name === '') return;
    setShowModal(true);
    Api.post(
      '/con/add',
      {
        name,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    )
      .then(() => {
        setShowModal(false);
        props.history.push('/dashboard');
      })
      .catch(err => {
        if (err.response.status === 403) {
          return history.push('/logout');
        }

        setError({
          status: true,
          message: 'Condomínio com esse nome já cadastrado!',
        });
        setShowModal(false);
      });
  };
  return (
    <div className={classes.Content}>
      <Modal show={showModal} Loading />
      <h1>Adicionar Condomínio</h1>
      <form onSubmit={onSubmitHandler}>
        <ErrorMsg errorInfo={error} setErrorInfo={setError} />
        <label htmlFor="name">Nome</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
        <button type="submit">Adicionar</button>
      </form>
    </div>
  );
};

export default AddCondo;
