import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

import classes from './ColorPicker.module.css';

const ColorPicker = props => {
  const [visible, setVisible] = useState(false);
  const selector = {
    width: '90px',
    height: '40px',
    borderRadius: '2px',
    backgroundColor: props.colorProp,
  };
  return (
    <div className={classes.MainDiv}>
      <label>{props.label}</label>
      <div>
        <div className={classes.OuterDiv}>
          <div style={selector} onClick={() => setVisible(v => !v)} />
        </div>
        {visible && (
          <>
            <div
              className={classes.ColorPickerDiv}
              onClick={() => setVisible(false)}
            />
            <div className={classes.Popover}>
              <SketchPicker
                color={props.colorProp}
                onChange={color => props.setColorProp(color.hex)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ColorPicker;
