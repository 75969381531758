import React, { useState, useContext } from 'react';

import { AuthContext } from '../../context/auth-context';
import Modal from '../../components/Layout/Modal/Modal';
import ErrorMsg from '../../components/Layout/ErrorMsg/ErrorMsg';
import Api from '../../services/api';
import classes from './Login.module.css';

export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const [isLogin, setIsLogin] = useState(true);
  const authContext = useContext(AuthContext);

  const onSubmitHandler = e => {
    e.preventDefault();
    setModal(true);
    if (isLogin) {
      Api.get('/login', {
        headers: {
          email,
          pass: password,
        },
      })
        .then(res => {
          setModal(false);
          authContext.login(res.data.token);
          props.history.push('/dashboard');
        })
        .catch(() => {
          setModal(false);
          setError({ status: true, message: 'Email e/ou senha inválidos!' });
        });
    } else {
      setModal(false);
      setError({
        status: true,
        message: 'A criação de contas está temporariamente desabilitada!',
      });
      /* if (password !== confirmPassword) {
        setModal(false);
        return setError({
          status: true,
          message: 'As senhas digitadas não são iguais!',
        });
      }
      Api.post(
        '/signup',
        {},
        {
          headers: {
            email,
            pass: password,
          },
        }
      )
        .then(() => {
          setModal(false);
          setError({ status: true, message: 'Conta cadastrada com sucesso!' });
          setIsLogin(true);
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          document.querySelectorAll('input').forEach(i => {
            i.value = '';
          });
        })
        .catch(() => {
          setModal(false);
          setError({ status: true, message: 'Email já cadastrado!' });
        }); */
    }
  };

  const handleLoginChange = login => {
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setIsLogin(login);
    setError({ status: false, message: '' });
    document.querySelectorAll('input').forEach(i => {
      i.value = '';
    });
  };

  return (
    <div className={classes.loginContainer}>
      <div className={classes.logo} />
      <Modal show={modal} Loading />
      <ErrorMsg errorInfo={error} setErrorInfo={setError} />
      <form onSubmit={onSubmitHandler}>
        <div>
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            autoComplete="username"
            onChange={e => setEmail(e.target.value.toLocaleLowerCase())}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
            required
          />
        </div>

        {!isLogin && (
          <div>
            <label htmlFor="password">Confirmar Senha</label>
            <input
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
          </div>
        )}
        <button type="submit">{isLogin ? 'Fazer Login' : 'Criar Conta'}</button>
        {isLogin ? (
          <p>
            Ainda não tem uma conta?{' '}
            <button type="button" onClick={() => handleLoginChange(false)}>
              Criar conta
            </button>
          </p>
        ) : (
          <p>
            Já tem uma conta?{' '}
            <button type="button" onClick={() => handleLoginChange(true)}>
              Fazer login
            </button>
          </p>
        )}
      </form>
    </div>
  );
}
