export const setCssVars = () => {
  const accentColor = localStorage.getItem('accentColor');
  const backgroundColor = localStorage.getItem('backgroundColor');
  const highlightColor = localStorage.getItem('highlightColor');
  const contrastColor = localStorage.getItem('contrastColor');
  document.documentElement.style.setProperty(
    '--accent-color',
    accentColor || '#00a8ff'
  );

  document.documentElement.style.setProperty(
    '--background-color',
    backgroundColor || '#161618'
  );

  document.documentElement.style.setProperty(
    '--highlight-color',
    highlightColor || '#292932'
  );

  document.documentElement.style.setProperty(
    '--contrast-color',
    contrastColor || '#bbbbca'
  );
};
