import React from 'react';
import { useHistory } from 'react-router-dom';

import classes from './Logo.module.css';

const Logo = () => {
  const history = useHistory();

  return <div className={classes.Logo} onClick={() => history.push('/')} />;
};

export default Logo;
