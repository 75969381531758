import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import Modal from '../Layout/Modal/Modal';
import Api from '../../services/api';
import classes from './RemHouse.module.css';

const RemHouse = props => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });
  const [selectedHouse, setSelectedHouse] = useState('');
  const history = useHistory();
  const location = useLocation();
  const { houses, conId } = props;

  useEffect(() => {
    if (houses.length === 0)
      return history.push(location.pathname.replace('/rem', ''));
  }, [houses, history, location]);

  const handleSubmit = e => {
    e.preventDefault();
    setModal(true);
    Api.delete(`/con/${conId}/rem/${selectedHouse}`, {
      headers: {
        token: localStorage.getItem('token'),
      },
    })
      .then(() => {
        setModal(false);
        history.push(location.pathname.replace('/rem', ''));
      })
      .catch(() => {
        setModal(false);
        setError({ status: true, message: 'Erro ao remover casa!' });
      });
  };

  return (
    <>
      <Modal show={modal} Loading />
      <div className={classes.Content}>
        <h1>Remover Casa</h1>
        <form onSubmit={handleSubmit}>
          <ErrorMsg errorInfo={error} setErrorInfo={setError} />
          <label>Casa</label>
          <select
            value={selectedHouse}
            onChange={e => setSelectedHouse(e.target.value)}
            required
          >
            <option value="" disabled hidden>
              Selecione
            </option>
            {houses.map(hous => (
              <option key={hous.houseId} value={hous.houseId}>
                {hous.address}
              </option>
            ))}
          </select>
          <button type="submit">Remover</button>
        </form>
      </div>
    </>
  );
};

export default RemHouse;
