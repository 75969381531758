import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ErrorMsg from '../Layout/ErrorMsg/ErrorMsg';
import Modal from '../Layout/Modal/Modal';
import Api from '../../services/api';
import classes from './RemCondo.module.css';

const RemCondo = props => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({ status: false, message: '' });
  const [selectedCondo, setSelectedCondo] = useState('');
  const history = useHistory();
  const { condos } = props;

  useEffect(() => {
    if (condos.length === 0) return history.push('/dashboard');
  }, [condos, history]);

  const handleSubmit = e => {
    e.preventDefault();
    setModal(true);
    Api.delete(`/con/rem/${selectedCondo}`, {
      headers: {
        token: localStorage.getItem('token'),
      },
    })
      .then(() => {
        setModal(false);
        history.push('/dashboard');
      })
      .catch(() => {
        setModal(false);
        setError({ status: true, message: 'Erro ao remover condomínio!' });
      });
  };

  return (
    <>
      <Modal show={modal} Loading />
      <div className={classes.Content}>
        <h1>Remover Condomínio</h1>
        <form onSubmit={handleSubmit}>
          <ErrorMsg errorInfo={error} setErrorInfo={setError} />
          <label>Condomínio</label>
          <select
            value={selectedCondo}
            onChange={e => setSelectedCondo(e.target.value)}
            required
          >
            <option value="" disabled hidden>
              Selecione
            </option>
            {condos.map(con => (
              <option key={con.condoId} value={con.condoId}>
                {con.name}
              </option>
            ))}
          </select>
          <button type="submit">Remover</button>
        </form>
      </div>
    </>
  );
};

export default RemCondo;
