import React, { useState /* , useContext, useEffect */ } from 'react';
import { useHistory } from 'react-router-dom';

import classes from './Config.module.css';
import ColorPicker from '../../components/Layout/ColorPicker/ColorPicker';
import ToggleSwitch from '../../components/Layout/ToggleSwitch/ToggleSwitch';
import { setCssVars } from '../../functions/functions';
// import { AuthContext } from '../../context/auth-context';

const Config = () => {
  // const authContext = useContext(AuthContext);
  const history = useHistory();
  const [accentColor, setAccentColor] = useState(
    localStorage.getItem('accentColor') || '#00a8ff'
  );
  const [checked, setChecked] = useState(
    localStorage.getItem('theme') === 'dark' ||
      localStorage.getItem('theme') === null
  );

  /* useEffect(() => {
    if (!authContext.isAuth) {
      history.replace('/');
    }
  }, [history, authContext.isAuth]); */

  const saveColors = () => {
    if (accentColor !== '#00a8ff') {
      localStorage.setItem('accentColor', accentColor);
    }
    setCssVars();
  };

  const setDefaultColors = () => {
    localStorage.removeItem('accentColor');
    history.go();
  };

  const handleThemeChange = c => {
    if (!c) {
      localStorage.setItem('backgroundColor', '#E2DEDE');
      localStorage.setItem('highlightColor', '#D2D2D4');
      localStorage.setItem('contrastColor', '#2E2E30');
    } else {
      localStorage.removeItem('backgroundColor');
      localStorage.removeItem('highlightColor');
      localStorage.removeItem('contrastColor');
    }
    const ch = c ? 'dark' : 'light';
    localStorage.setItem('theme', ch);
    setCssVars();
    setChecked(c);
  };

  return (
    <div className={classes.Content}>
      <ColorPicker
        colorProp={accentColor}
        setColorProp={setAccentColor}
        label="Cor Principal"
      />

      <ToggleSwitch
        checked={checked}
        setChecked={handleThemeChange}
        color={accentColor}
        label="Tema Escuro"
      />
      <div className={classes.Buttons}>
        <button type="button" onClick={saveColors}>
          Salvar Cor Principal
        </button>
        <button type="button" onClick={setDefaultColors}>
          Cor Principal Padrão
        </button>
      </div>
    </div>
  );
};

export default Config;
